import Vue from "vue";
import Vuex from "vuex";

import api from "./socialhubapi";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    users: [],
    is_typing_users: [],
    all_conversations: [],
    current_conversation: {},
    current_messages: [],
    current_images: [],
  },
  getters: {
    currentConversation: (state) => state.current_conversation,
    currentMessages: (state) => state.current_messages,
    currentUsers: (state) => state.users,
    isTypingUsers: (state) => state.is_typing_users,
    currentImages: (state) => state.current_images,
  },
  mutations: {
    setAllConversations(state, all_conversations) {
      state.all_conversations = all_conversations;
    },
    setUsers(state, users) {
      state.users = users.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
      }, {});
    },
    setIsTypingUsers(state, is_typing_user_ids) {
      state.is_typing_users = is_typing_user_ids.map((user_id) => {
        return state.users[user_id];
      });
    },
    clearCurrentConversation(state) {
      state.current_conversation = {};
      state.current_messages = [];
      state.current_images = [];
    },
    setCurrentConversation(state, current_conversation) {
      state.current_conversation = current_conversation;
    },
    addChat(state, chat) {
      state.all_conversations.push(chat);
    },
    setCurrentMessages(state, current_messages) {
      let last_from_user_id = null;
      let last_timestamp = null;

      state.current_messages = current_messages.map((message) => {
        message["from_user"] = state.users[message.from_user_id];

        // Also populate "last_read_users" with a list of actual users
        message["last_read_users"] = message.last_read_user_ids.map((user_id) => {
          return state.users[user_id];
        });

        // Set "hide_details_as_repeat_message" if this message is from the same user
        // as the last one, as is timestamped within 30 minutes of the previous message
        let hideDetails =
          message.from_user_id == last_from_user_id &&
          Date.parse(message.date) - Date.parse(last_timestamp) < 30 * 60 * 1000;
        if (hideDetails) {
          message.hide_details_as_repeat_message = true;
        } else {
          message.hide_details_as_repeat_message = false;
        }

        last_from_user_id = message.from_user_id;
        last_timestamp = message.date;

        return message;
      });
    },
    setCurrentImages(state, current_images) {
      state.current_images = current_images;
    },
    addGalleryImage(state, message) {
      state.current_images.push(message);
    },
    addMessage(state, message) {
      message.hide_details_as_repeat_message = true;
      state.current_messages.push(message);
    },
    removeMessage(state, item) {
      state.current_messages.find((message) => message.id == item.id);
    },
  },
  actions: {
    getChats({ commit }) {
      return new Promise((resolve) => {
        api
          .getChats()
          .then((response) => {
            commit("setAllConversations", response.data.all_conversations);
            resolve();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getChat({ commit }, { id, messages_limit }) {
      return new Promise((resolve) => {
        api.getChat(id, messages_limit).then((response) => {
          let current_images = response.data.messages.filter((message) => message.attachment_is_image);
          commit("setUsers", response.data.users);
          commit("setIsTypingUsers", response.data.is_typing_user_ids);
          commit("setCurrentConversation", response.data.conversation);
          commit("setCurrentMessages", response.data.messages);
          commit("setCurrentImages", current_images);

          resolve();
        });
      });
    },
    addChat({ commit }, chat_name) {
      return new Promise((resolve, reject) => {
        api
          .createChat(chat_name)
          .then((response) => {
            commit("setCurrentConversation", response.data.conversation);

            resolve(response.data.conversation.id);
          })
          .catch((error) => {
            reject(error.response.data.error);
          });
      });
    },
    updateIsTyping(user_is_typing) {
      api.updateIsTyping(this.state.current_conversation.id, user_is_typing);
    },
    addMessage({ commit }, formData) {
      return new Promise((resolve, reject) => {
        api
          .sendMessage(this.state.current_conversation.id, formData)
          .then((response) => {
            commit("addMessage", response.data);

            if (response.data.attachment_is_image) {
              commit("addGalleryImage", response.data);
            }

            resolve();
          })
          .catch((error) => {
            reject(error.response.data.error);
          });
      });
    },
    removeMessage({ commit }, message) {
      return new Promise((resolve, reject) => {
        api
          .deleteMessage(
            // this.state.current_conversation.id,
            message
          )
          .then((response) => {
            commit("removeMessage", response.data);
            resolve();
          })
          .catch((error) => {
            reject(error.response.data.error);
          });
      });
    },
  },
});
