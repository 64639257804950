import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    favourites: [],
  },
  plugins: [new VuexPersistence().plugin],
  getters: {
    isFavouriteInList: (state) => (favourite) => {
      return state.favourites.findIndex((f) => f.id == favourite.id) >= 0;
    },
    favouritesCount: (state) => () => {
      return state.favourites.length;
    },
  },
  mutations: {
    addFavourite(state, favourite) {
      state.favourites.push(favourite);
    },
    removeFavourite(state, favourite) {
      let idx = state.favourites.findIndex((f) => f.id == favourite.id);
      if (idx >= 0) {
        state.favourites.splice(idx, 1);
      }
    },
  },
  actions: {},
});
