/* eslint-disable */
export default {
  methods: {
    handleContentClick: function (evt) {
      let target = this.isDescendantOfLink(evt.target) ? this.isDescendantOfLink(evt.target) : evt.target;

      if (target.tagName === "A") {
        evt.preventDefault();

        console.log(window);

        if (window.cordova) {
          this.openUrlInApp(target.href);
        } else {
          this.openUrlOnWebsite(target.href);
        }
      }
    },
    openUrlOnWebsite(link) {
      const linkUrl = new URL(link);

      if (linkUrl.host === process.env.VUE_APP_WEBSITE_HOST || linkUrl.hostname === "localhost") {
        this.openInternalPage(linkUrl);
      } else {
        window.open(link, "_blank");
      }
    },
    openUrlInApp(link) {
      if (link.indexOf("mailto:") > -1) {
        const email = link.replace("mailto:", "");

        cordova.plugins.email.open({
          to: email,
        });
      } else if (link.indexOf("tel:") > -1) {
        const number = link.replace("tel:", "");

        cordova.plugins.phonedialer.dial(number);
      } else if (link.indexOf("sms:") > -1) {
        const number = link.replace("sms:", "");
        const message = "";
        const options = {
          replaceLineBreaks: false,
          androidIntent: 'INTENT'
        };

        document.addEventListener("deviceready", () => {
          const permissions = cordova.plugins.permissions;

          const sendSMS = () => {
            sms.send(number, message, options, () => {
              console.log("SMS app opened successfully.");
            }, (error) => {
              console.error("Failed to open SMS app:", error);
            });
          };

          const checkAndRequestSMSPermission = () => {
            permissions.checkPermission(permissions.SEND_SMS, (status) => {
              if (status.hasPermission) {
                sendSMS();
              } else {
                permissions.requestPermission(permissions.SEND_SMS, (status) => {
                  if (status.hasPermission) {
                    sendSMS();
                  } else {
                    console.error("SMS permission denied");
                  }
                }, (error) => {
                  console.error("Permission request failed", error);
                });
              }
            }, (error) => {
              console.error("Permission check failed", error);
            });
          };

          checkAndRequestSMSPermission();
        }, false);
      } else if (
        window.device.platform === "Android" &&
        (link.endsWith(".pdf") ||
          link.endsWith(".docx") ||
          link.endsWith(".doc") ||
          link.endsWith(".xls") ||
          link.endsWith(".xlsx") ||
          link.endsWith(".ppt") ||
          link.endsWith(".pptx"))
      ) {
        window.cordova.InAppBrowser.open(link, "_system");
      } else {
        const linkUrl = new URL(link);

        if (linkUrl.host === process.env.VUE_APP_WEBSITE_HOST || linkUrl.hostname === "localhost") {
          // need to know ios host name
          this.openInternalPage(linkUrl);
        } else {
          let linkPath = link.indexOf("http") > -1 ? link : "http://" + link;

          let browserConfig =
            "location=no,footer=yes,footercolor=#CC000000,closebuttoncaption=Done,closebuttoncolor=#FFFFFF";

          if (window.device.platform === "iOS") {
            browserConfig = "toolbarposition=top,closebuttoncolor=#000000,toolbarcolor=#cccccc,location=no";
          }

          window.cordova.InAppBrowser.open(linkPath, "_blank", browserConfig);
        }
      }
    },
    openInternalPage(linkUrl) {
      let linkPath = "";

      if (linkUrl.hash) {
        linkPath = linkUrl.hash.replace("#", "");
      } else if (linkUrl.pathname !== undefined && linkUrl.pathname !== "/") {
        linkPath = linkUrl.pathname;
      }

      console.log(linkPath);

      this.$router.push({ path: linkPath });
    },
    isDescendantOfLink(child) {
      var node = child.parentNode;

      while (node != null) {
        if (node.tagName == "A") {
          return node;
        }
        node = node.parentNode;
      }

      return false;
    },
  },
};
/* eslint-enable */
