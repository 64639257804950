import axios from "axios";
import store from "./store";

const API_URL = process.env.API_URL || "http://preview.wiltshire.secure-messaging.co.uk";
// const API_URL = process.env.API_URL || "http://localhost:3000";

// `baseURL` is prepended to URLs passed to axios

// POST with JSON content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// `xsrfCookieName` is the name of the cookie
// to use as a value for xsrf token.
// Default is 'XSRF-TOKEN'
axios.defaults.xsrfCookieName = "CSRF-TOKEN";

// `xsrfHeaderName` is the name of the http header
// that carries the xsrf token value.
// Default is 'X-XSRF-TOKEN'
axios.defaults.xsrfHeaderName = "X-CSRF-Token";

// `withCredentials` indicates whether or not cross-site
// Access-Control requests should be made using credentials.
axios.defaults.withCredentials = true;

const socialhubAxios = axios.create({
  baseURL: API_URL,
});

export default {
  setAuthorization() {
    if (store.state.user) {
      socialhubAxios.defaults.headers.common["Authorization"] = store.state.user.authorization;
    }
  },
  //createSession, deleteSession and getTenants do not require authorization token to be sent, therefore no setAuthorization
  createSession(email, password) {
    return socialhubAxios.post("/sessions", {
      email: email,
      password: password,
      tenant: "wiltshire",
    });
  },

  deleteSession() {
    return socialhubAxios.delete("/sessions", {});
  },
  getTenants() {
    return socialhubAxios.get("/tenants");
  },
  //setAuthorization required for all other methods below
  createChat(chat_name) {
    this.setAuthorization();
    return socialhubAxios.post("/conversations.json", { conversation: { name: chat_name } });
  },
  getChats() {
    this.setAuthorization();
    return socialhubAxios.get("/conversations.json").catch(() => {
      console.error("Unauthorized! Signing out...");
      store.dispatch("signOut").then(() => {
        if (
          router.currentRoute.name === "chat_single" ||
          router.currentRoute.name === "chat_new" ||
          router.currentRoute.name === "chats"
        ) {
          router.replace("/sign-in");
        }
      });
    });
  },
  getChat(id, messages_limit) {
    this.setAuthorization();
    return socialhubAxios.get(`/conversations/${id}/messages.json?limit=${messages_limit}`);
  },
  getEvents(search_query) {
    this.setAuthorization();
    return socialhubAxios.get(`/events.json${search_query}`);
  },
  showInterest(event) {
    this.setAuthorization();
    return socialhubAxios.post(`/events/${event.id}/event_interests`);
  },
  removeInterest(event) {
    this.setAuthorization();
    return socialhubAxios.delete(`/events/${event.id}/event_interests`);
  },
  getPosts(search_query, posts_limit) {
    this.setAuthorization();
    return socialhubAxios.get(`/posts.json?limit=${posts_limit}${search_query}`);
  },
  getNotifications(last_read_timestamp) {
    this.setAuthorization();
    return socialhubAxios.get("/notifications.json", { params: { last_read_timestamp: last_read_timestamp } });
  },
  removePost(post_id) {
    this.setAuthorization();
    return socialhubAxios.delete(`/posts/${post_id}`);
  },
  removeComment(comment_id) {
    this.setAuthorization();
    return socialhubAxios.delete(`/comments/${comment_id}`);
  },
  reportPost({ item_id, item_type }) {
    this.setAuthorization();
    return socialhubAxios.post("/reported_items.json", {
      report_item: {
        item_id: item_id,
        item_type: item_type,
      },
    });
  },
  likePost(post) {
    this.setAuthorization();
    return socialhubAxios.post(`/posts/${post.id}/likes`);
  },
  unlikePost(post) {
    this.setAuthorization();
    return socialhubAxios.delete(`/posts/${post.id}/likes`);
  },
  likeComment(post_id, comment_id) {
    this.setAuthorization();
    return socialhubAxios.post(`/posts/${post_id}/comments/${comment_id}/likes`);
  },
  unlikeComment(post_id, comment_id) {
    this.setAuthorization();
    return socialhubAxios.delete(`/posts/${post_id}/comments/${comment_id}/likes`);
  },
  addComment(data) {
    this.setAuthorization();
    return socialhubAxios.post("/comments/", data);
  },
  updateIsTyping(id, user_is_typing) {
    this.setAuthorization();
    return socialhubAxios.post("/conversations/" + id + "/messages.json", { is_typing: user_is_typing });
  },
  sendMessage(id, data) {
    this.setAuthorization();
    return socialhubAxios.post("/conversations/" + id + "/messages.json", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  sendPost(data) {
    this.setAuthorization();
    return socialhubAxios.post("/posts.json", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getGroups() {
    this.setAuthorization();
    return socialhubAxios.get("/groups/mine.json");
  },
  getAnswers(survey_id) {
    this.setAuthorization();
    return socialhubAxios.get(`/surveys/${survey_id}/answers/edit.json`);
  },
  submitAnswers({ survey_id, answers }) {
    this.setAuthorization();
    return socialhubAxios.post(`/surveys/${survey_id}/answers.json`, { answers: answers });
  },
  getSurveys(search_query) {
    this.setAuthorization();
    return socialhubAxios.get(`/surveys.json${search_query}`);
  },
  deleteMessage(message) {
    this.setAuthorization();
    if (message.removed) {
      message.removed = false;

      socialhubAxios.delete(`/messages/${message.id}?restore=true`).then((response) => {
        return response;
        // Nothing to do; UI has already been updated
      });
    } else {
      message.removed = true;

      return socialhubAxios.delete(`/messages/${message.id}`).then(() => {
        // Nothing to do; UI has already been updated
      });
    }
  },
  createUserRequest(user_request) {
    this.setAuthorization();
    return socialhubAxios.post("/account.json", {
      user_request: {
        name: user_request.display_name,
        avatar: user_request.new_attachment,
        first_name: user_request.first_name,
        last_name: user_request.last_name,
        email: user_request.email,
        password: user_request.password,
        password_confirmation: user_request.password_confirmation,
        agreed_terms: user_request.agreed_terms,
        subdomain: user_request.subdomain,
      },
    });
  },
  updateUser(user_request) {
    this.setAuthorization();
    return socialhubAxios.put("/account.json", user_request, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

// Intercept all 401 Unauthorized responses before
// they are handled by `then` or `catch`

import router from "./router";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      console.error("Unauthorized! Signing out...");
      store.dispatch("signOut").then(() => {
        router.replace("/sign-in");
      });
    }
    return Promise.reject(error);
  }
);
