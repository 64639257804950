// import colors from 'vuetify/lib/util/colors'
export default {
  primary: "#02040f",
  secondary: "#f90",
  icons: "#006750",
  light: "#000000",
  dark: "#292929",
  info: "#00663d",
  accent: "#00aeef",
  grey: "#bebebe",
  lightBlue: "#44CCFF",
  red: "#FE4369",
  lightGreen: "#97d1ac",
  toolbar: "#f90",

  green: "#5AD882",
  blue: "#00c2cc",
  yellow: "#fefb9a",
  pink: "#f6a2af",
  black: "#02040f",
};
