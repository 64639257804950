<template>
  <div v-if="hubbdata">
    <v-navigation-drawer
      app
      v-model="drawer"
      :fixed="$vuetify.breakpoint.smAndDown"
      :temporary="$vuetify.breakpoint.smAndDown"
      :permanent="$vuetify.breakpoint.mdAndUp"
    >
      <v-btn
        icon
        large
        :aria-label="hubbdata.toolBar.closeMenu"
        @click="toggleDrawer"
        class="v-navigation-drawer__close-menu-btn hidden-md-and-up"
      >
        <svg
          width="25"
          height="25"
          style="enable-background: new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          class="v-navigation-drawer__close-menu-icon"
        >
          <path
            d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"
            fill="#00C2CC"
          />
        </svg>
      </v-btn>

      <div class="v-navigation-drawer__logo-wrapper">
        <v-img alt="FocusGov" contain class="v-navigation-drawer__logo" src="@/assets/images/logo.png" />
      </div>

      <v-list nav dense class="pa-4">
        <v-list-item-group aria-label="Navigation Items" class="pa-0" role="navigation">
          <v-list-item :to="{ name: 'home' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.tabs.home }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'locations' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.localOffer }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'regional_offer' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.regionalOffer }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'organisations' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.helpfulOrganisations }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'events' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.whatsOn }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'jobs_and_opportunities' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.jobOpportunities }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'offers' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.discountsOffers }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item link :to="{ name: 'news_archive' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.news }}
            </v-list-item-title>
          </v-list-item>

          <!-- <v-list-item :to="{ name: 'budget_planner' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.budgetPlanner }}
            </v-list-item-title>
          </v-list-item> -->

          <!-- <v-list-item :to="{ name: 'toolkit' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.mentalHealthToolkit }}
            </v-list-item-title>
          </v-list-item> -->

          <v-list-item :to="{ name: 'vips' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.vips }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'favourites' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.favourites }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'contact' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.contactUs }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'legal' }" :ripple="false" class="pa-0">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.legal }}
            </v-list-item-title>
          </v-list-item>

          <Settings />
        </v-list-item-group>

        <v-list-item class="v-navigation-drawer__lang-select-list-item pa-0">
          <v-list-item-title>
            <label for="languageSelect">
              {{ hubbdata.settings.text.languages }}
            </label>
          </v-list-item-title>

          <v-select
            v-model="selectedLang"
            :items="langs"
            menu-props="auto"
            item-text="name"
            item-value="code"
            solo
            @change="updateLang(selectedLang)"
            :aria-label="hubbdata.settings.text.languages"
            hide-details
            id="languageSelect"
            class="v-navigation-drawer__language-select"
          />
        </v-list-item>

        <v-list-item class="v-navigation-drawer__tour-list-item pa-0">
          <v-btn
            class="v-navigation-drawer__tour-btn"
            @click="restartTheTour"
            v-html="hubbdata.tutorial.startTutorial"
            block
          />
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app elevation="0" :class="[{ 'v-toolbar--cutout': cutout }]">
      <v-app-bar-nav-icon
        icon
        large
        @click="toggleDrawer"
        :aria-label="hubbdata.toolBar.openMenu"
        id="menu-btn"
        class="v-toolbar__open-menu-btn v-toolbar__btn hidden-md-and-up"
        data-cypress-value="menu"
      >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.57143 11.2165H10.3237C10.8167 11.2165 11.2165 10.8167 11.2165 10.3237V3.57143C11.2165 1.60217 9.61434 0 7.64509 0H3.57143C1.60217 0 0 1.60217 0 3.57143V7.64509C0 9.61434 1.60217 11.2165 3.57143 11.2165Z"
            fill="#00C2CC"
          />
          <path
            d="M21.4286 0H17.3549C15.3857 0 13.7835 1.60217 13.7835 3.57143V10.3237C13.7835 10.8167 14.1833 11.2165 14.6763 11.2165H21.4286C23.3978 11.2165 25 9.61434 25 7.64509V3.57143C25 1.60217 23.3978 0 21.4286 0Z"
            fill="#00C2CC"
          />
          <path
            d="M3.57143 25H7.64509C9.61434 25 11.2165 23.3978 11.2165 21.4286V14.6763C11.2165 14.1833 10.8167 13.7835 10.3237 13.7835H3.57143C1.60217 13.7835 0 15.3857 0 17.3549V21.4286C0 23.3978 1.60217 25 3.57143 25Z"
            fill="#00C2CC"
          />
          <path
            d="M21.4286 13.7835H14.6763C14.1833 13.7835 13.7835 14.1833 13.7835 14.6763V21.4286C13.7835 23.3978 15.3857 25 17.3549 25H21.4286C23.3978 25 25 23.3978 25 21.4286V17.3549C25 15.3857 23.3978 13.7835 21.4286 13.7835Z"
            fill="#00C2CC"
          />
        </svg>
      </v-app-bar-nav-icon>

      <button
        v-if="showBackBtn"
        type="button"
        large
        @click="$router.go(-1)"
        class="v-toolbar__btn v-toolbar__btn--ml"
        aria-label="Go back to previous page"
      >
        <v-icon class="v-toolbar__btn-icon">mdi-chevron-left</v-icon>
        {{ hubbdata.tutorial.buttons.back }}
      </button>

      <v-spacer></v-spacer>

      <v-btn
        v-if="favouritesCount > 0"
        icon
        large
        :aria-label="hubbdata.toolBar.favouritesAria"
        :to="{ name: 'favourites' }"
        id="favourites-btn"
        class="v-toolbar__btn"
      >
        <v-badge :content="favouritesCount" overlap dark>
          <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25.313 11.8352C25.9729 11.1923 26.2058 10.2487 25.921 9.3731C25.6367 8.49713 24.8937 7.87068 23.9819 7.73819L18.3523 6.92021C18.0666 6.8785 17.8196 6.69912 17.6919 6.44034L15.1742 1.33864C14.7666 0.512926 13.9412 0 13.0202 0C12.0992 0 11.2738 0.512926 10.8662 1.33864L8.34852 6.44029C8.22076 6.69917 7.97384 6.87855 7.68851 6.92016L2.05858 7.73814C1.14675 7.87068 0.403736 8.49713 0.11958 9.37264C-0.165391 10.2487 0.0674988 11.1924 0.727208 11.8351L4.80102 15.8065C5.00766 16.0078 5.10196 16.2979 5.05328 16.582L4.09141 22.1896C3.93599 23.0974 4.30213 23.9976 5.04698 24.5386C5.79198 25.0799 6.76138 25.1501 7.57697 24.7217L12.6122 22.0743C12.8677 21.9401 13.1727 21.9401 13.4278 22.0741L18.4635 24.7217C19.2791 25.1501 20.2485 25.0799 20.9935 24.5386C21.7383 23.9975 22.1045 23.0974 21.9491 22.1896L20.9872 16.5823C20.9385 16.2979 21.0327 16.0078 21.2395 15.8063L25.313 11.8352Z"
              fill="#FEFB9A"
            />
          </svg>
        </v-badge>
      </v-btn>

      <v-btn
        icon
        large
        :aria-label="hubbdata.toolBar.searchAria"
        :to="{ name: 'search' }"
        class="v-toolbar__btn v-toolbar__btn--ml"
      >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24.3083 21.4106L22.3348 19.4371C21.923 19.0252 21.8364 18.3877 22.132 17.8859C23.4818 15.5949 24.0911 12.8194 23.6041 9.88114C22.7577 4.7745 18.5448 0.737532 13.4084 0.0954002C5.66499 -0.872633 -0.872633 5.66499 0.0954002 13.4084C0.737532 18.5448 4.7745 22.7577 9.88114 23.6041C12.8194 24.0911 15.5949 23.4818 17.8859 22.132C18.3878 21.8364 19.0252 21.923 19.4371 22.3348L21.502 24.3997C21.9018 24.7999 22.4266 25 22.9508 25C23.5249 25 24.0995 24.7601 24.5102 24.2802C25.2202 23.4502 25.0807 22.1829 24.3083 21.4106ZM4.2077 13.2061C3.32794 7.88516 7.88516 3.32802 13.2061 4.20778C16.4298 4.74073 19.0297 7.34073 19.5627 10.5644C20.4425 15.8854 15.8852 20.4425 10.5643 19.5628C7.34073 19.0298 4.74073 16.4298 4.2077 13.2061Z"
            fill="#F6A2AF"
          />
        </svg>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import hubbdata from "@/hubbdata";
import favourites from "@/favourites";
import Settings from "@/components/Header/Settings.vue";

export default {
  components: {
    Settings,
  },
  name: "Header",
  data: () => ({
    drawer: false,
    selectedLang: localStorage.getItem("current_lang"),
    alert: false,
    dialog: false,
    fontAlert: false,
    openDyslexic: false,
  }),
  mounted() {
    if (localStorage.getItem("enable_font_change") === "true") {
      this.openDyslexic = true;
    } else {
      this.openDyslexic = false;
    }

    /// listening for language change emit coming form settings.vue
    /// we're passing the 'language' and then setting is as a selected one
    this.$bus.$on("language-changed-from-settings-page", (language) => {
      this.selectedLang = language;
    });
  },
  beforeDestroy() {
    // stop listening for the emit coming from settings.vue
    this.$bus.$off("language-changed-from-settings-page");
  },
  methods: {
    restartTheTour() {
      this.drawer = !this.drawer;
      localStorage.removeItem("has_seen_the_tour");
      this.$router.push({ name: "home" });
      this.$tours["myTour"].start();
    },
    signOut() {
      this.$store.dispatch("signOut").then(() => {
        this.$router.push({ name: "user_sign_in" });
      });
    },
    toggleDrawer() {
      this.drawer = !this.drawer;

      setTimeout(() => {
        if (document.getElementsByClassName("v-navigation-drawer--open").length) {
          document.getElementsByClassName("v-navigation-drawer__close-menu-btn")[0].focus();
        } else {
          document.getElementsByClassName("v-toolbar__open-menu-btn")[0].focus();
        }
      }, 100);
    },
    updateFont(font) {
      localStorage.setItem("enable_font_change", font);
      hubbdata.dispatch("updateFont", font);
      this.fontAlert = true;
    },
    updateLang(selectedLang) {
      hubbdata.dispatch("updateLang", selectedLang).then(() => {
        hubbdata.dispatch("initDB").then(() => {});
      });
      this.alert = true;
      // send a global emit back to Settings.vue with the newly selected language
      this.$bus.emit("language-changed-from-header", this.selectedLang);
    },
  },
  computed: {
    hubbdata() {
      return hubbdata.getters.translations;
    },
    favouritesCount() {
      return favourites.getters.favouritesCount();
    },
    showBackBtn() {
      if (this.$route.name !== "home" && window.history.length > 1) {
        return true;
      }

      return false;
    },
    cutout() {
      if (
        this.$route.name === "locations" ||
        this.$route.name === "organisations" ||
        this.$route.name === "events" ||
        this.$route.name === "jobs_and_opportunities" ||
        this.$route.name === "offers" ||
        this.$route.name === "budget_planner" ||
        this.$route.name === "news_archive" ||
        this.$route.name === "vips" ||
        this.$route.name === "toolkit" ||
        this.$route.name === "toolkit_archive" ||
        this.$route.name === "toolkit_article" ||
        this.$route.name === "regional_offer"
      ) {
        return true;
      }

      return false;
    },
    langs() {
      return hubbdata.getters.getLanguages;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-toolbar {
  background-color: $black !important;

  &--cutout {
    background-color: unset !important;
    background: radial-gradient(circle at 50% 54px, rgba(0, 0, 0, 0) 35px, $black 36px, $black 100%) !important;
  }

  &__title {
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
  }

  &__btn {
    display: flex;
    align-items: center;
    color: $white !important;
    font-size: 0.875rem;
    font-weight: 700;
    padding-right: 5px;
    border-radius: 3px;

    &--ml {
      margin-left: 5px;
    }

    &:hover,
    &:focus {
      outline: 2px solid #fff;
    }
  }

  &__btn-icon {
    color: #fff !important;
  }

  ::v-deep .v-badge__badge {
    background-color: $blue !important;
    color: $black;
    font-weight: 600;
  }
}

.v-navigation-drawer {
  background-color: $black !important;

  &__close-menu-btn {
    position: absolute;
    top: 6px;
    left: 100%;
    margin-left: 10px;

    &:hover,
    &:focus {
      outline: 3px solid #fff;
    }
  }

  &__logo-wrapper {
    display: flex;
    justify-content: center;
    padding: 16px;
    background: linear-gradient(180deg, rgba(90, 216, 130, 0.79) 30%, #00c2cc 100%);
  }

  &__logo {
    width: 60px;
    height: 60px;
  }

  &__lang-select-list-item {
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 0 !important;

    &::after {
      display: none !important;
    }
  }

  &__language-select {
    margin-top: 10px;

    &.v-input {
      font-size: 1rem;
    }

    &.v-input--is-focused {
      outline: 3px solid #fff;
      outline-offset: 2px;
    }

    ::v-deep .v-select__selections {
      line-height: 1.3;
    }
  }

  &__tour-list-item {
    margin-top: 30px;
  }

  &__tour-btn {
    width: 100%;
    white-space: normal;
    min-height: 36px;
    height: auto !important;

    &:hover,
    &:focus {
      outline: 3px solid #fff;
      outline-offset: 2px;
    }
  }
}
.v-navigation-drawer {
  overflow: unset;

  &__content {
    overflow: auto;
  }
}
.v-list--dense {
  .v-list-item .v-list-item__title {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    white-space: normal;
    word-break: break-word;
  }

  .v-item-group .v-list-item {
    margin-bottom: 6px !important;

    &:hover,
    &:focus {
      outline: 3px solid #fff;
      outline-offset: 1px;
    }
  }

  i {
    color: #fff;
    font-size: 10px;
    padding-right: 10px;
  }
}
</style>
