import Vue from "vue";
import Vuex from "vuex";

import api from "./socialhubapi";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem("user")),
    registration_notice: "",
    tenants: [],
    connected: navigator.onLine
  },
  getters: {
    isAuthenticated: state => !!state.user,
  },
  mutations: {
    setRegistrationConfirmation(state) {
      state.registration_notice = "Thank you for signing up, your account is in moderation and will require approval by the care team.";
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    setTenants(state, tenants) {
      console.log(tenants)
      state.tenants = tenants.tenants
      console.log(state.tenants) 
    },
    clearUser(state) {
      state.user = null;
      localStorage.removeItem("user");
    }
  },


  actions: {
    registerUser({ commit }, newUserRequest) {
      console.log(commit);
      return new Promise ((resolve) => {
        api.createUserRequest(newUserRequest)
        .then(() => {
          commit("setRegistrationConfirmation")
          resolve();
        })
      });
    },
    getTenants({ commit }) {
      // The Promise used for router redirect in Header.vue
      console.log("getting")

      return new Promise(resolve => {
        api.getTenants().then((response) => {
          console.log(response.data)

          commit("setTenants", response.data);
          console.log(this.state.tenants)

          resolve();
        });
      });
    },
  
    signIn({ commit }, { username, password }) {
      // The Promise used for router redirect in Signin.vue

      return new Promise((resolve, reject) => {
        api
          .createSession(username, password, 'wiltshire')
          .then(response => {
            commit("setUser", response.data);
            resolve();
          })
          .catch(error => {
            // if the request fails, clear user
            commit("clearUser");
            reject(error.response);
          });
      });
    },

    signOut({ commit }) {
      // The Promise used for router redirect in Header.vue
      return new Promise(resolve => {
        api.deleteSession().then(() => {
          commit("clearUser");
          resolve();
        });
      });
    },
    updateUser({ dispatch }, user_request) {
      return new Promise((resolve, reject) => {
        api.updateUser(user_request)
        .then(response => {
          dispatch("signOut").then(() => {
              resolve(response.data.messages);
            })
          })
          .catch(error => {
            reject(error.response.data.messages);
          })
      })
    },
  }
});
