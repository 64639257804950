<template>
  <v-app :class="[hubbdata.openDyslexic ? 'open-dyslexic' : '']">
    <BypassBlock
      v-if="$route.path != '/welcome' && $route.name != 'user_register' && $route.path != '/terms'"
    ></BypassBlock>

    <Header v-if="$route.path != '/welcome' && $route.path != '/terms'"> </Header>

    <v-main id="main-content" tabindex="-1">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath" />
      </transition>

      <v-alert v-model="networkAlert" dense dismissible type="error" class="network-error"
        >Network Error. Unable to fetch live data</v-alert
      >
    </v-main>
  </v-app>
</template>

<script>
import oneSignal from "@/mixins/oneSignal.js";
import Header from "@/components/Header/Header";
import chat from "@/chat";
import hubbdata from "@/hubbdata";
import BypassBlock from "./components/App/BypassBlock.vue";

export default {
  name: "App",
  components: {
    Header,
    BypassBlock,
  },
  mixins: [oneSignal],
  data: () => ({
    alert: false,
    openDyslexic: false,
  }),
  computed: {
    current_users() {
      return chat.state.users;
    },
    translations() {
      return hubbdata.getters.translations;
    },
    hubbdata() {
      return hubbdata.state;
    },
    networkAlert: {
      get: function () {
        return hubbdata.getters.getNetworkAlert;
      },
      set: function (newValue) {
        hubbdata.commit("setNetworkAlert", newValue);
      },
    },
  },
  mounted() {
    if (localStorage.getItem("current_lang") !== null) {
      hubbdata.state.current_lang = localStorage.getItem("current_lang");
    } else {
      hubbdata.state.current_lang = "en";
    }
    hubbdata.dispatch("initDB");
    hubbdata.dispatch("setFont");
  },
};
</script>
<style lang="scss">
.border .v-main {
  margin-bottom: 0px !important;
  border: solid 6px $primary;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  color: black !important;
  background-color: white;
}

////// APP TOUR PLUGIN /////
.v-step {
  background: $white !important;
  color: $black !important;
  max-width: 280px !important;
  border-radius: 15px !important;
}

.v-step__header {
  background: $white !important;
  color: $black !important;
  border-bottom: 3px solid $black;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
}

.v-step__content {
  font-size: 0.875rem;
  color: $black;
}

.v-step__button {
  border: none !important;
  background-color: $black !important;
  color: $white !important;
  font-size: 0.875rem !important;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  padding: 5px 20px !important;
  height: auto !important;
  margin: 0 5px !important;
  border-radius: 5px !important;
}

.v-step__arrow::before {
  background: $black !important;
}

.network-error {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  border-radius: 0 !important;
  font-weight: 700;

  @media (min-width: 960px) {
    left: 256px;
  }

  .v-btn {
    &:before {
      display: none;
    }

    &:focus,
    &:hover {
      outline: 2px solid $white;
      outline-offset: 2px;
    }
  }
}
</style>
