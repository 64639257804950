<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="settings-button">
        <v-list-item :ripple="false" class="pa-0">
          <i class="mdi mdi-checkbox-blank"></i>
          <v-list-item-title>{{ translations.settings.title }}</v-list-item-title>
        </v-list-item>
      </v-btn>
    </template>

    <v-card class="settings">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
          <span class="sr-only">{{ translations.settings.text.close }}</span>
        </v-btn>
        <v-toolbar-title>{{ translations.settings.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-container>
        <v-row>
          <v-col cols="12">
            <label class="settings__label" for="fonts" id="languageSelect">
              {{ translations.settings.text.changedefault }} {{ fontAlert }}
            </label>

            <v-switch
              v-model="openDyslexic"
              name="fonts"
              class="d-block w-100"
              width="100%"
              :label="`OpenDyslexic`"
              @change="updateFont(openDyslexic)"
              solo
              hide-details
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <fieldset class="settings__checkbox-fieldset">
              <legend class="settings__checkbox-legend">
                {{ translations.welcome.user_type_heading }}
              </legend>

              <div class="settings__checkbox-container">
                <v-checkbox
                  v-model="selectedUserType"
                  value="Professional"
                  name="user_type"
                  color="primary"
                  :label="translations.welcome.professional_label"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="selectedUserType"
                  value="Care Leaver"
                  name="user_type"
                  color="primary"
                  :label="translations.welcome.care_leaver_label"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="selectedUserType"
                  value="Foster Carer"
                  name="user_type"
                  color="primary"
                  :label="translations.welcome.foster_carer_label"
                  hide-details
                ></v-checkbox>
              </div>
            </fieldset>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <label for="langs" class="settings__label">
              {{ translations.settings.text.languages }}
            </label>

            <v-select
              v-model="selectedLang"
              :items="langs"
              item-text="name"
              item-value="code"
              @change="updateLang(selectedLang)"
              solo
              hide-details
              id="langs"
              name="langs"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <label class="settings__label" for="user-location">
              {{ translations.welcome.location_heading }}
            </label>

            <v-select
              :items="locations"
              :hint="locationsHint"
              v-model="selectedUserLocation"
              solo
              hide-details="auto"
              persistent-hint
              id="user-location"
              name="user-location"
            ></v-select>
          </v-col>
        </v-row>

        <v-alert
          close-text="Close Alert"
          color="#dee0e2"
          rounded="false"
          dismissible
          icon="mdi-alert-circle"
          class="banner settings_page-alerts"
          light
          @input="DismissFontAlert()"
          v-if="fontAlert"
        >
          <span v-if="openDyslexic">
            {{ translations.settings.text.textopen }}
          </span>
          <span v-else>
            {{ translations.settings.text.textdefault }}
          </span>
        </v-alert>

        <v-alert
          close-text="Close Alert"
          color="#dee0e2"
          rounded="false"
          dismissible
          icon="mdi-alert-circle"
          class="banner settings_page-alerts"
          light
          @input="DismissAlert()"
          v-if="alert"
        >
          {{ translations.settings.text.languageset }} {{ currentLang.name }}
        </v-alert>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import oneSignal from "@/mixins/oneSignal.js";
import hubbdata from "@/hubbdata";

export default {
  name: "Settings",
  mixins: [oneSignal],
  data: () => ({
    drawer: false,
    selectedLang: localStorage.getItem("current_lang"),
    dialog: false,
    fontAlert: false,
    alert: false,
    openDyslexic: false,
  }),
  methods: {
    updateLang(selectedLang) {
      hubbdata.dispatch("updateLang", selectedLang).then(() => {
        hubbdata.dispatch("initDB").then(() => {});
      });
      this.alert = true;
      // send a global emit back to Header.vue with the newly selected language
      this.$bus.emit("language-changed-from-settings-page", this.selectedLang);
    },
    updateFont(font) {
      localStorage.setItem("enable_font_change", font);
      console.log(font);
      hubbdata.dispatch("updateFont", font);
      this.fontAlert = true;
    },
    DismissAlert() {
      this.alert = false;
    },
    DismissFontAlert() {
      this.fontAlert = false;
    },
    sendGoogleAnalyticsEvent() {
      /* eslint-disable */
      gtag("event", "user_type", {
        value: this.selectedUserType,
      });
      /* eslint-enable */
    },
  },
  computed: {
    langs() {
      return hubbdata.getters.getLanguages;
    },
    currentLang() {
      return this.langs.find((lang) => lang.code === this.selectedLang);
    },
    translations() {
      return hubbdata.getters.translations;
    },
    locationsHint() {
      if (this.selectedLocation && this.selectedLocation === "All Locations") {
        return this.translations.welcome.all_locations_hint;
      } else if (this.selectedLocation) {
        let hintText = this.translations.welcome.other_locations_hint.replace("[location]", this.selectedLocation);
        return hintText;
      }

      return "";
    },
    selectedUserLocation: {
      get: function () {
        return hubbdata.getters.getUserLocation;
      },
      set: function (newValue) {
        hubbdata.dispatch("saveUserLocation", newValue);

        this.updateOneSignalLocation(newValue);
      },
    },
    selectedUserType: {
      get: function () {
        return hubbdata.getters.getUserType;
      },
      set: function (newValue) {
        hubbdata.dispatch("saveUserType", newValue);

        this.sendGoogleAnalyticsEvent();
      },
    },
    locations() {
      return hubbdata.getters.getLocations;
    },
  },
  mounted() {
    console.log(localStorage.getItem("enable_font_change"));
    if (localStorage.getItem("enable_font_change") === "true") {
      this.openDyslexic = true;
    } else {
      this.openDyslexic = false;
    }

    /// listening for language change emit coming form Header.vue
    /// we're passing the 'language' and then setting is as a selected one
    this.$bus.$on("language-changed-from-header", (language) => {
      this.selectedLang = language;
    });
  },
  beforeDestroy() {
    // stop listening for the emit coming from Header.vue
    this.$bus.$off("language-changed-from-header");
  },
};
</script>

<style lang="scss" scoped>
.v-list--dense {
  .v-list-item .v-list-item__title {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
  }

  .v-item-group .v-list-item {
    margin-bottom: 0px !important;

    &:hover,
    &:focus {
      outline: 3px solid #fff;
      outline-offset: 1px;
    }
  }

  .v-list-item:before {
    display: none !important;
  }

  i {
    color: #fff;
    font-size: 10px;
    padding-right: 10px;
  }

  .settings-button {
    height: unset !important;
    min-width: unset !important;
    padding: 0 !important;
    width: 100% !important;
    background: transparent !important;
    text-align: left !important;
    font-size: 0 !important;
    text-transform: none !important;

    &:focus,
    &:hover {
      outline: 3px solid #fff;
      outline-offset: 1px;
    }
  }
}

.settings {
  &__label {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 900;
    line-height: 1.3;
    color: $black;
  }

  &__checkbox-fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  &__checkbox-legend {
    font-size: 16px;
    font-weight: 900;
  }

  &__checkbox-container {
    display: flex;
    column-gap: 30px;

    @media (max-width: 700px) {
      flex-direction: column;
    }
  }

  ::v-deep {
    .v-select {
      margin-top: 13px !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      border-radius: 0 !important;
    }
  }
}

.v-alert {
  margin: 10px 0;
}
</style>
