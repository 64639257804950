import Vue from "vue";
import VueRouter from "vue-router";
// import goTo from 'vuetify/es5/services/goto'
import store from "../store";

// import About from '../views/About.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "home" },
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  {
    path: "/sign-in",
    name: "user_sign_in",
    component: () => import(/* webpackChunkName: "about" */ "../views/UserSignIn.vue"),
  },
  {
    path: "/register",
    name: "user_register",
    component: () => import(/* webpackChunkName: "register" */ "../views/UserRegister.vue"),
  },
  // {
  //   path: "legal/about",
  //   name: "about",
  //   component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/chats",
    name: "chats",
    component: () => import(/* webpackChunkName: "chats" */ "../views/Chats.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/posts",
    name: "posts",
    component: () => import(/* webpackChunkName: "Posts" */ "../views/Posts.vue"),
  },
  {
    path: "/posts/new",
    name: "posts_new",
    component: () => import(/* webpackChunkName: "Posts" */ "../views/PostsNew.vue"),
  },
  {
    path: "/events",
    name: "events",
    component: () => import(/* webpackChunkName: "Events" */ "../views/Events.vue"),
  },
  {
    path: "/legal/",
    name: "legal",
    component: () => import(/* webpackChunkName: "Legal" */ "../views/Legal.vue"),
  },
  {
    path: "/chats/:id",
    name: "chat_single",
    component: () => import(/* webpackChunkName: "chats" */ "../views/ChatSingle.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/new-chat",
    name: "chat_new",
    component: () => import(/* webpackChunkName: "chats" */ "../views/ChatNew.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/surveys",
    name: "surveys",
    component: () => import(/* webpackChunkName: "Surveys" */ "../views/Surveys.vue"),
  },
  {
    path: "/simple_surveys",
    name: "simple_surveys",
    component: () => import(/* webpackChunkName: "SimpleSurveys" */ "../views/SimpleSurveys.vue"),
  },
  {
    path: "/surveys/:id",
    name: "survey_questions",
    component: () => import(/* webpackChunkName: "Surveys" */ "../views/SurveyQuestions.vue"),
  },
  {
    path: "/user/edit",
    name: "user_edit",
    component: () => import(/* webpackChunkName: "Surveys" */ "../views/UserEdit.vue"),
  },
  {
    path: "/cms_pages/:id",
    name: "cms_page",
    component: () => import(/* webpackChunkName: "CmsPage" */ "../views/CmsPage.vue"),
  },
  {
    path: "/organisations/:id",
    name: "organisation",
    component: () => import(/* webpackChunkName: "CmsPage" */ "../views/Organisation.vue"),
  },
  {
    path: "/event/:id",
    name: "event",
    component: () => import(/* webpackChunkName: "CmsPage" */ "../views/Event.vue"),
  },
  {
    path: "/organisations/",
    name: "organisations",
    component: () => import(/* webpackChunkName: "CmsPage" */ "../views/Organisations.vue"),
  },
  {
    path: "/contact/",
    name: "contact",
    component: () => import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
  },
  {
    path: "/welcome/",
    name: "welcome",
    component: () => import(/* webpackChunkName: "Welcome" */ "../views/Welcome.vue"),
  },
  {
    path: "/terms/",
    name: "terms",
    component: () => import(/* webpackChunkName: "Terms" */ "../views/Terms.vue"),
  },
  {
    path: "/app-search/",
    name: "search",
    component: () => import(/* webpackChunkName: "Search" */ "../views/AppSearch.vue"),
  },
  {
    path: "/favourites",
    name: "favourites",
    component: () => import(/* webpackChunkName: "Search" */ "../views/Favourites.vue"),
  },
  {
    path: "/vips",
    name: "vips",
    component: () => import(/* webpackChunkName: "Search" */ "../views/Vips.vue"),
  },
  {
    path: "/infoandadvice",
    name: "infoandadvice",
    component: () => import(/* webpackChunkName: "Search" */ "../views/InfoAndAdvice.vue"),
  },
  {
    path: "/local-offer-in-your-area",
    name: "locations",
    component: () => import(/* webpackChunkName: "Locations" */ "../views/Locations.vue"),
  },
  {
    path: "/local-offer-in-your-area/:location",
    name: "location_info",
    component: () => import(/* webpackChunkName: "LocationsInfo" */ "../views/LocationInfo.vue"),
  },
  {
    path: "/legal/:path",
    name: "legal_info",
    component: () => import(/* webpackChunkName: "LegalInfo" */ "../views/LegalInfo.vue"),
  },
  {
    path: "/regional-offer/",
    name: "regional_offer",
    component: () => import(/* webpackChunkName: "LegalInfo" */ "../views/RegionalOffer.vue"),
  },
  {
    path: "/jobs-and-opportunities",
    name: "jobs_and_opportunities",
    component: () => import(/* webpackChunkName: "JobsOpportunities" */ "../views/JobsOpportunities.vue"),
  },
  {
    path: "/jobs-and-opportunities/:category",
    name: "jobs_and_opportunities",
    component: () => import(/* webpackChunkName: "Opportunities" */ "../views/Opportunities.vue"),
  },
  {
    path: "/opportunity/:id",
    name: "Opportunity",
    component: () => import(/* webpackChunkName: "Search" */ "../views/Opportunity.vue"),
  },
  {
    path: "/discounts-and-offers",
    name: "offers",
    component: () => import(/* webpackChunkName: "Offers" */ "../views/OffersView.vue"),
  },
  {
    path: "/discounts-and-offers/:id",
    name: "offer",
    component: () => import(/* webpackChunkName: "Offer" */ "../views/OfferView.vue"),
  },
  {
    path: "/budget-planner",
    name: "budget_planner",
    component: () => import(/* webpackChunkName: "BudgetPlanner" */ "../views/BudgetPlanner.vue"),
  },
  // {
  //   path: "/mental-health-toolkit",
  //   name: "toolkit",
  //   component: () => import(/* webpackChunkName: "Toolkit" */ "../views/Toolkit.vue"),
  // },
  // {
  //   path: "/mental-health-toolkit/archive",
  //   name: "toolkit_archive",
  //   component: () => import(/* webpackChunkName: "ToolkitArchive" */ "../views/ToolkitArchive.vue"),
  //   query: {
  //     category: "",
  //     search: "",
  //   },
  // },
  // {
  //   path: "/mental-health-toolkit/:id",
  //   name: "toolkit_article",
  //   component: () => import(/* webpackChunkName: "ToolkitArticle" */ "../views/ToolkitArticle.vue"),
  // },
  {
    path: "/news",
    name: "news_archive",
    component: () => import(/* webpackChunkName: "NewsArchive" */ "../views/NewsArchive.vue"),
  },
  {
    path: "/news/:id",
    name: "news_article",
    component: () => import(/* webpackChunkName: "NewsArticle" */ "../views/NewsArticle.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      setTimeout(function () {
        window.scroll(0, savedPosition.y);
      }, 50);
      return;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== "welcome" &&
    (localStorage.getItem("current_lang") === null ||
      localStorage.getItem("user_type") === null ||
      localStorage.getItem("user_location") === null ||
      localStorage.getItem("terms_accepted") === null)
  ) {
    next({ name: "welcome" });
  } else if (
    to.name === "welcome" &&
    localStorage.getItem("current_lang") &&
    localStorage.getItem("user_type") &&
    localStorage.getItem("user_location") &&
    localStorage.getItem("terms_accepted")
  ) {
    next({ name: "home" });
  } else {
    next();
  }
});

function requireAuth(to, from, next) {
  if (store.getters.isAuthenticated) {
    next();
  } else {
    next({
      path: "/sign-in",
      query: { redirect: to.fullPath },
    });
  }
}

export default router;
