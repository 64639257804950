import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import light from './themes/default'

Vue.use(Vuetify);

export default new Vuetify({
  options: {
    customProperties: true
  },
    theme: {
      themes: { 
        light,
        info: {
          primary: "#00663d",
          secondary: "#E53935",
          accent: "#00aeef",
          error: "#E53935",
        }

        // light: {
        //   primary: colors.pink.darken4, // #E53935
        //   secondary: colors.pink.darken2, // #FFCDD2
        //   accent: colors.pink.base, // #3F51B5
        //   anchor: colors.indigo.base
        // },
      },
    },
  })